import React from "react";
import Image from "next/image";
import Link from "next/link";

export function ShieldCard({ imageUrl1, imageAlt1,imageUrl2, imageAlt2,imageUrl3, imageAlt3, children, subheading, normaltext }) {
     return (
        <div className="sectiondivide ShieldCard">
            <div className="innercontent">
                <div className="ShieldCardhead">
                    <h2>{children}</h2>
                    {normaltext}
                    <p>{subheading}</p>
                </div>
                <div className="ShieldCardcon">
                    <div className="cardshield">
                        <Image src={imageUrl1} alt={imageAlt1} width={500} height={500} />
                    </div>
                    <div className="cardshield">
                        <Image src={imageUrl2} alt={imageAlt2} width={500} height={500} />
                    </div>
                    <div className="cardshield">
                        <Image src={imageUrl3} alt={imageAlt3} width={500} height={500} />
                    </div>
                </div>
            </div>
        </div>
     )
    }
export default ShieldCard;