import React, { useState } from "react";
import Image from 'next/image'
import { Helmet } from "react-helmet";
import AddUser from "@/components/AddUser/AddUser"
import PageLoader from '@/components/PageLoader/PageLoader';
import Productcard from "@/components/Productcard/Productcard"
import ShieldCard from "@/components/ShieldCard/ShieldCard"
import HeroSection from "@/components/HeroSection/HeroSection"
import Collapsible from "@/components/Collapsible/Collapsible"
import ImgAccordian from "@/components/ImgAccordian/ImgAccordian"
import SecurityProtocol from "@/components/SecurityProtocol/SecurityProtocol"
import Link from "next/link";
import Head from 'next/head';

function Home() {

  const tabData = [
    { title: '1. DIY Shopping List', content: 'Adaptive lists publicly-traded options for a portfolio based on desired protection period & level.', imgsrc: '/app/Assets/risktools/diy_shopping_list.svg', link: '/tools/ppc', linkText: 'Click to Try!' },
    { title: '2. Risk Contribution', content: 'Adaptive can flag concentrations of risk within a portfolio, for example identifying the stocks with outsized contributions to a portfolio’s overall volatility.', imgsrc: '/app/Assets/risktools/risk_contribution.svg', link: '/tools/riskcontribution', linkText: 'Sample Here!' },
    { title: '3. Backtest & Monte Carlo', content: 'Quantify downside protection benefit scenarios in which downside protection makes the most of market downturns—not only reducing a portfolio’s risk, but also potentially producing superior long-term growth thanks to the power of compounding growth & reinvestment of downside protection payoffs at lower prices during episodes of market decline.', imgsrc: '/app/Assets/risktools/backtest_monte_carlo.svg', link: '/tools/mc', linkText: 'Sample Here!' },
    { title: '4. Factor Analysis', content: 'Measure a portfolio’s fit compared to various market indexes in pursuit of cost-effective, right-sized market hedges.', imgsrc: '/app/Assets/risktools/factor_analysis.svg', link: '/tools/factor_analysis', linkText: 'Sample Here!' },
  ];
  return (
    <>
      <PageLoader />
      <Head>
        <title>Adaptive One-Click Downside Protection: Easy Changes Everything</title>
        <meta property="og:title" content="Adaptive One-Click Downside Protection: Easy Changes Everything" />
        <meta property="og:site_name" content="Adaptive Investments" />
        <meta property="og:description" content="One-click downside protection that is easy-to-use, cost-effective, and personalized to your own investment portfolio." />
        <meta property="og:image" content={process.env.NEXT_PUBLIC_WEBSITE_URL + "Assets/Adaptive-Platform-Illustration.png"} />
        <meta name="keywords" content="Adaptive, AdaptiveInvestmentSolutions, Adaptive Investment Solutions, Downside Protection, DownsideProtection, VIX, Implied Volatility, ImpliedVolatility, Market Risk, MarketRisk, Volatility Tax, VolatilityTax" />
      </Head>
      <HeroSection
        title="Stay invested for long-term growth with Adaptive one-click downside protection"
        subtitle="RADICALLY EASY-TO-USE portfolio Protection"
        content="Hedging doesn’t have to be hard or hideously expensive. Play defense like the pros with Adaptive’s easy-to-use, cost-effective portfolio protection to limit losses & make the most of all-to-common market downturns."
        hreflink="https://app2.adaptive-investments.com/advisorsignup"
        linktext="Free Trial"
        showSignupForm="True"
      >
        <Image src="/app/Assets/homehero.svg" width="630" height="400" />
      </HeroSection>
      <div className="sectiondivide vcolumn gap100" id="section1">
        <div className="featureview context">
          <div className="sectiontitle">
            <p className="subhighlight">What We Do</p>
            <h2>
              Limit losses & dial down portfolio risk with personalized hedging
            </h2>
          </div>
          <div className="productcardsec">
            <Productcard
              imageUrl="/app/Assets/cardicon/advisors.svg"
              imageAlt="A description of the image"
              title="For Advisors"
              link="/what_we_serve/AdvisorsWealthManagers"
            >
              Adaptive risk tools & downside protection deliver peace of mind for prospects & existing clients, from retirement savers to high-net-worth families.
            </Productcard>
            <Productcard
              imageUrl="/app/Assets/cardicon/self-managed.svg"
              imageAlt="A description of the image"
              title="For Self-Managed"
              link="/what_we_serve/RetailInvestors"
            >
              Retail investors can fine-tune risk in asset allocations, opportunistically hedge news cycles, & boost compounding in market downturns.
            </Productcard>
            <Productcard
              imageUrl="/app/Assets/cardicon/enterprise.svg"
              imageAlt="A description of the image"
              title="For Enterprise"
              link="/what_we_serve/Enterprises"
            >
              Comprehensive enterprise API access with secure integration of risk analytics & portfolio protection for brokers & institutions.
            </Productcard>
          </div>
        </div>
        <div className="featureview context">
          <div className="sectiontitle">
            <p className="subhighlight">Easy Changes Everything</p>
            <h2>
              Adaptive makes hedging easy, with one-click protection & DIY risk tools
            </h2>
          </div>
          <div className="productcardsec">
            <Productcard
              imageUrl="/app/Assets/cardicon/marketshield.svg"
              imageAlt="A description of the image"
              title="Market Shield"
              link=""
            >
              Estimates protection price based on <Link className="ai-link" href="https://www.cboe.com/tradable_products/sp_500/mini_spx_options/" target="_blank">Mini-SPX Put Options on the S&P 500 market index</Link> for a market hedge that is liquid & often relatively inexpensive.
            </Productcard>
            <Productcard
              imageUrl="/app/Assets/cardicon/marketshieldplus.svg"
              imageAlt="A description of the image"
              title="Market Shield+"
              link=""
            >
              Estimates protection price based on <Link className="ai-link" href="https://indexes.nasdaqomx.com/Index/Overview/XND" target="_blank">Micro-XND Put Options on the NASDAQ 100 Market Index</Link> & other indexes for a market hedge that could be a better fit than Market Shield.
            </Productcard>
            <Productcard
              imageUrl="/app/Assets/cardicon/riskanalytics.svg"
              imageAlt="A description of the image"
              title="Risk Analytics"
              link=""
            >
              Compare market-driven costs & factor analysis for do-it-yourself downside protection, including back tests, Monte Carlo simulations, & risk contribution scores for portfolio components.
            </Productcard>
          </div>
        </div>
      </div>
      <ShieldCard
        imageUrl1="/app/Assets/shield_card/Link_Account.svg"
        imageAlt1=""
        imageUrl2="/app/Assets/shield_card/Protection_Price_Estimate.svg"
        imageAlt2=""
        imageUrl3="/app/Assets/shield_card/Protection_Agreement.svg"
        imageAlt3=""
        //subheading="Adaptive is as easy as 1-2-3: get quick, specific recommendations & price quotes to limit downside risk for your selected protection level & time period."
        normaltext="Adaptive is as easy as 1-2-3: get quick, specific recommendations & price quotes to limit downside risk for your selected protection level & time period."
      >
        Managing <span>market downturns</span> just got easier
      </ShieldCard>
      <div className="sectiondivide sectioncol sectiondividenopad">
        <div className="innercontentleft">
          <h3 className="sec-head">Use Cases</h3>
          <div className="faqcon">
            <Collapsible title="Better Investing with Better Tools">
              Advisors & investors who want to employ downside protection must contend with complexity, inefficient pricing, & a lack of customized offerings—often leaving clients exposed to excessive risk & unnecessary taxes. Adaptive makes hedging easy.
            </Collapsible>
            <Collapsible title="Simplify Options Complexity">
              Hedging is hard. Without Adaptive, hedging for downside protection is complicated & time-consuming. We make it easy-to-use, cost-effective, & highly personalized with one-click downside protection.
            </Collapsible>
            <Collapsible title="Stay Invested for Long-Term Growth">
              Protection premiums can be a drag on performance, but more than pay for themselves if they help investors remain invested for long-term growth. Reinvestment of protection payouts can lead to outperformance on a risk-adjusted basis.
            </Collapsible>
            <Collapsible title="De-risk Concentrated Positions">
              Taxable gains present challenges to diversifying concentrated portfolios or maintaining portfolio allocations. Adaptive Shield offers immediate downside protection to allow gradual and deliberate rebalancing.
            </Collapsible>
            <Collapsible title="Manage Risk & Limit Losses">
              Risk management is a challenge even for professional investors. Adaptive makes it simple to limit losses & dial-down portfolio risk across multiple client accounts, including risk analytic tools, buy-limit purchases, & price alerts to add protection when it is on sale.
            </Collapsible>
            <Collapsible title="Panic Protection">
              Market pullbacks are common. S&P 500 declines of at least 10% have happened about once a year since WWII. Bear markets on average last roughly 13 months. Downside protection help investors get invested & stay invested, to make compounding work even harder for long-term returns.
            </Collapsible>
            <Collapsible title="Fractionalized Hedges">
              Mismatched position sizes lead to over & under Hedging. Option contracts are sold in 100-share lots, posing a problem for smaller accounts & odd-lot share counts. Adaptive aggregates portfolio protection to efficiently hedge odd share lots, through an aggregated book & pass along savings to clients.
            </Collapsible>
          </div>
        </div>
      </div>
      <div className="sectiondivide twocol bgsecondary">
        <div className="leftsection">
          <Image src="/app/Assets/API_Code.svg" className="fluidimg" width="500" height="500" />
        </div>
        <div className="rightsection">
          <h2 className="sec-head left">Enterprise API</h2>
          <p className="graycon">Our RESTful, easy-to-implement API streamlines risk analytics & protection quotes in real time for seamless integration with existing trading systems. Simply specify portfolio holdings with the desired protection level & protection period.</p>
          <Link href="/tools/enterprise_api" target="_blank" className="button">See API</Link>
        </div>
      </div>
      <div className="sectiondivide whitesec p0i">
        <div className="innercontent">
          <div className="ShieldCardhead">
            <h2>Play <span>defense</span> like the pros</h2>
            Preview Adaptive’s powerful risk tools (no login required) to compare market-driven costs for downside protection, including Adaptive’s Portfolio Protection Calculator to estimate the cost of downside protection for Boglehead & other popular model portfolios.
          </div>
          <div className="risktools">
            <ImgAccordian tabData={tabData}></ImgAccordian>
          </div>
        </div>
      </div>
      <div className="sectiondivide jcenter">
        <SecurityProtocol
          title="Industry leading security protocols"
          imgsrc="/app/Assets/key.svg"
        >
          Full end-to-end SaaS with <b>industry-leading security protocols</b>, leveraging trusted broker & banking integration for <b>read-only access</b> to client account holdings.
        </SecurityProtocol>
      </div>
      <AddUser showDetailsForm={true} />
    </>
  );
}

export default Home;
