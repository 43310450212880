import React from "react";
import Image from "next/image";
import Link from "next/link";

export function Productcard({ imageUrl, imageAlt, title, link, children }) {
    return (
        <div className="productcard">
            <Image src={imageUrl} alt={imageAlt} width={500} height={500} />
            <h3>{title}</h3>
            <p>{children}</p>
            {link && <Link href={link} className="ai-link">Read More</Link>}
        </div>
    )
    }
export default Productcard;