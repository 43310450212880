import React, { useState } from 'react';
import Image from 'next/image';
import Link from "next/link";

const ImgAccordian = ({ tabData}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex === activeTab ? null : tabIndex);
  };

  return (
    <div className="faqcon imgaccod">
      {tabData.map((tab, index) => (
        <div key={index} className="faq">
          <h4 onClick={() => handleTabClick(index)} className={activeTab === index ? 'active' : ''}>
            {tab.title} <span>{activeTab === index ? '-' : '+'}</span>
          </h4>
          {activeTab === index && (
            <>
              <div className="content">
                {tab.content}{" "}
                {tab.link && <Link href={tab.link} target="_blank">{tab.linkText}</Link>}
              </div>
              <div className="imgcon"><Image src={tab.imgsrc} width="500" height="500"/></div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default ImgAccordian;